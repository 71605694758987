import ScrollMagic from 'scrollmagic';

import {scrolEvents} from './default';
import {index} from './index';
import {food} from './food';
import {spa} from './spa';
import {room} from './room';
import {vm} from './vm';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    
    vm();
    scrolEvents(controller , scene);
    index(controller , scene);
    food(controller , scene);
    spa(controller , scene);
    room(controller , scene);
});
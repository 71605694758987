import Swiper from 'swiper/swiper-bundle.min';

export function room(controller , scene){
	const childSlide = document.querySelectorAll('.child');
	const parentSlide = document.querySelectorAll('.parent');
	let slideAry = [];

	for(var i = 0; i < parentSlide.length; i++){
		slideAry[i] = new Swiper(childSlide[i], {
			slidesPerView: 4,
			spaceBetween: 10,
		});

		new Swiper(parentSlide[i], {
			slidesPerView: 1,
			thumbs: {
				swiper: slideAry[i]
			},
		});
	}
};
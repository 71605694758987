import Swiper from 'swiper/swiper-bundle.min';
import * as Masonry from 'masonry-layout';
import * as imagesLoaded from 'imagesloaded';

export function food(controller , scene){
	const foodgal = document.getElementById('foodgal');
	if(foodgal){
		new Swiper(foodgal, {
			slidesPerView: 'auto',
			centeredSlides: true,
			loop: true,
			pagination: {
				el: ".foodgalpager",
				clickable: true,
			},
		});
	}
	
	const gridGallery = document.getElementById('masonry');
	if(gridGallery){
		imagesLoaded(gridGallery, function(){
			new Masonry(gridGallery , {
				itemSelector: '.grid-item'
			});
			gridGallery.classList.add('active');
		});
	}
};
import $ from 'jquery';
import ripples from 'jquery.ripples';
import * as imagesLoaded from 'imagesloaded';

export function spa(controller , scene){
	
	const spaRip = document.getElementById('spaRip');
	if(spaRip){
		imagesLoaded(spaRip, function(){
			$('#spaRip').ripples({
				resolution: 512,
				dropRadius: 20,
				perturbance: 0.04,
			});
		});
	}
};
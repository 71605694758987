import Swiper from 'swiper/swiper-bundle.min';
import ScrollMagic from 'scrollmagic';
import $ from 'jquery';
import ripples from 'jquery.ripples';
import * as imagesLoaded from 'imagesloaded';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;

export function index(controller , scene){
	
	const fv = document.getElementById('firstview');
	if(fv){
		setTimeout(function(){
			fv.style.maxHeight = window.innerHeight + 'px';
		},80);
	}
	
	const rippleEl = document.getElementById('ripples');
	if(rippleEl){
		imagesLoaded(rippleEl, function(){
			$('.ripples').ripples({
				resolution: 512,
				dropRadius: 20,
				perturbance: 0.04,
			});
		});
	}
	
	const galSlider = document.getElementById('galswipe');
	if(galSlider){
		new Swiper(galSlider, {
			slidesPerView: "auto",
			spaceBetween: 40,
			loop: true,
			navigation: {
				prevEl: ".galnext"
			},
		});
	}
	
	const planSlider = document.getElementById('planswipe');
	if(planSlider){
		new Swiper(planSlider, {
			slidesPerView: "auto",
			spaceBetween: 0,
			loop: true,
    		pagination: {
    			el: '.plan-pagination',
    			type: 'fraction',
    			renderFraction: function (currentClass, totalClass) {
    				return 'SLIDES. <span class="' + currentClass + '"></span> / <span class="' + totalClass + '"></span>';
    			}
    		}
		});
	}
	
    if(document.getElementById('indexRetxt')){
        scene.push(
            new ScrollMagic.Scene({tweenChanges: false,triggerElement: "#indexRetxt", duration: '300%', offset: '-800'})
                .setTween("#indexRetxt", { left: '30%' })
                .addTo(controller)
        );
    }
};